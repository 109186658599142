import { LoadableAfterPaint } from '@confluence/loadable';

import { AIContentActionsMenuSplitButtonPlaceholder } from './AIContentActionsMenuSplitButtonPlaceholder';

export const AIContentActionsMenuSplitButton = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AIContentActionsMenuSplitButton" */ './AIContentActionsMenuSplitButton'
			)
		).AIContentActionsMenuSplitButton,
	loading: AIContentActionsMenuSplitButtonPlaceholder,
});
