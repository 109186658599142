import { FABRIC_PAGE_CONTENT_ID } from './constants';
import { getClosestScrolledElementIndex } from './getClosestScrolledElementIndex';

export const rememberScrolledPosition = (contentId: string): void => {
	// root page element
	const page: HTMLElement = document.documentElement;

	// get fabric or classic renderer content id
	const pageContent: HTMLElement | null = document.getElementById(FABRIC_PAGE_CONTENT_ID);

	// if there's no page content, do nothing
	if (!pageContent) {
		return;
	}

	// if the user is already at the top of the page, then no need to scroll
	if (page.scrollTop / page.clientHeight < 0.25) {
		return;
	}

	// save closest scrolled element & contentId to localstorage
	getClosestScrolledElementIndex(page, pageContent, contentId);
};
