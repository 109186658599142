import React from 'react';

import Button from '@atlaskit/button/new';
import { Flex, xcss } from '@atlaskit/primitives';
import Skeleton from '@atlaskit/skeleton';

import { AISummaryIcon } from '../../../../common/assets/AISummaryIcon';

const styles = xcss({ height: '32px' });
export const AISummarizeButtonPlaceholder = () => (
	<Button
		testId="ai-summarize-button-placeholder"
		isDisabled
		iconBefore={() => <AISummaryIcon label="" />}
	>
		<Flex xcss={styles} justifyContent="center" alignItems="center">
			<Skeleton borderRadius={4} isShimmering width={76} height={20} />
		</Flex>
	</Button>
);
