import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { styled } from '@compiled/react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { token } from '@atlaskit/tokens';

import { BannerStateContainer } from '@confluence/banners';

import { ContentScreenBackgroundLayer } from './ContentScreenBackgroundLayer';

export const DEFAULT_SCREEN_STYLES = {
	position: 'relative',
	background: token('elevation.surface', '#fff'),
	paddingTop: '0',
	paddingRight: token('space.500', '40px'),
	paddingBottom: token('space.250', '20px'),
	paddingLeft: token('space.500', '40px'),
	minHeight: 'calc(100vh - 56px)',
	boxSizing: 'border-box',
	// added in for cover image
	'--full-cover-image-margin': '-40px', // should be the negative value of the padding of the container
	'--full-cover-image-width': 'calc(100% + 80px)', // should do calculations based on the padding of the container
};

export const LOADING_SCREEN_STYLES = {
	position: 'fixed',
	width: '100%',
	height: '100%',
	top: '0',
	display: 'none',
	bottom: '0',
	left: '0',
	right: '0',
	zIndex: '10',
	background: token('color.skeleton', 'rgba(0,0,0,0.5)'),
};

const LIVE_PAGES_SCREEN_STYLES = {
	paddingTop: '0px',
	paddingRight: '0px',
	paddingBottom: '0px',
	paddingLeft: '0px',
	minHeight: 'unset',
	// added in for cover image
	'--full-cover-image-margin': '0px', // should be the negative value of the padding of the container
	'--full-cover-image-width': '100%', // should do calculations based on the padding of the container
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OverflowHiddenDiv = styled.div({
	overflow: 'hidden',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PositionRelativeDiv = styled.div({
	position: 'relative',
});

export class ContentScreenComponent extends Component {
	static convertGutters(customScreenStyles) {
		if (customScreenStyles.gutterLeft === 'none') {
			customScreenStyles.paddingLeft = '0';
		}
		if (customScreenStyles.gutterRight === 'none') {
			customScreenStyles.paddingRight = '0';
		}
		if (customScreenStyles.gutterTop === '0px') {
			customScreenStyles.paddingTop = '0';
		}
		if (customScreenStyles.gutterBottom === '0px') {
			customScreenStyles.paddingBottom = '0';
		}

		return customScreenStyles;
	}

	static getStylesFromLookAndFeel(lookAndFeel) {
		if (!lookAndFeel || !lookAndFeel.content) {
			return {};
		}

		const screenLookAndFeel = ContentScreenComponent.convertGutters({
			...lookAndFeel.content.screen,
		});
		delete screenLookAndFeel.layer;

		return screenLookAndFeel;
	}

	getLivePagesStyles() {
		const { isLivePage } = this.props;
		return isLivePage ? LIVE_PAGES_SCREEN_STYLES : {};
	}

	getScreenStyles() {
		const { lookAndFeel, contentScreenStyles } = this.props;

		return {
			...DEFAULT_SCREEN_STYLES,
			...this.getLivePagesStyles(),
			...contentScreenStyles,
			...ContentScreenComponent.getStylesFromLookAndFeel(lookAndFeel),
			...this.getLoadingStyles(),
		};
	}

	getLoadingStyles() {
		const { isLoading } = this.props;
		return isLoading ? LOADING_SCREEN_STYLES : {};
	}

	adjustMinHeight(styles, bannerHeight) {
		// Update the minHeight style only if a banner is being shown and the minHeight is "100vh" (aka full height)
		const updatedStyles = { ...styles };
		if (updatedStyles.minHeight === '100vh' && bannerHeight > 0) {
			updatedStyles.minHeight = `calc(${updatedStyles.minHeight} - ${bannerHeight}px)`;
		}
		return updatedStyles;
	}

	renderContentScreen(shouldApplyTheme) {
		const { lookAndFeel, children } = this.props;
		return shouldApplyTheme ? (
			<Fragment>
				<ContentScreenBackgroundLayer lookAndFeel={lookAndFeel} />
				{/*
          For pages created by fabric we need to know the width of the content
          so we can use that to size breakout images/tables properly.
          This css class can be removed when Confluence adopts the Fabric renderer.
        */}
				<PositionRelativeDiv>
					{/*
            The next div is a hack to make sure lower elements with a margin have something to push against
          */}
					<OverflowHiddenDiv />
					{children}
				</PositionRelativeDiv>
			</Fragment>
		) : (
			<Fragment>{children}</Fragment>
		);
	}

	render() {
		const { isContentView, isThemed } = this.props;
		const shouldApplyTheme = isThemed || isContentView;
		const styles = shouldApplyTheme ? this.getScreenStyles() : this.getLoadingStyles();
		return (
			<Subscribe to={[BannerStateContainer]}>
				{(bannerState) => {
					const bannerHeight = bannerState.getTotalHeight();
					const updatedStyles = this.adjustMinHeight(styles, bannerHeight);

					return (
						<div
							data-testid="content-screen-component"
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className="content-screen-component"
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							style={updatedStyles}
						>
							{this.renderContentScreen(shouldApplyTheme)}
						</div>
					);
				}}
			</Subscribe>
		);
	}
}

ContentScreenComponent.defaultProps = {
	lookAndFeel: {
		content: {
			screen: DEFAULT_SCREEN_STYLES,
		},
	},
	isLivePage: false,
};

ContentScreenComponent.propTypes = {
	children: PropTypes.any,
	lookAndFeel: PropTypes.object,
	isThemed: PropTypes.bool,
	isContentView: PropTypes.bool,
	isLoading: PropTypes.bool,
	// This prop contains react-css values to customize the default content screen styles according to the consumer's need.
	contentScreenStyles: PropTypes.objectOf(PropTypes.string),
	isLivePage: PropTypes.bool,
};
