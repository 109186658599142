import type { IframePassThroughProps } from '@atlassian/embedded-confluence/IframeElementType';
import { useReactProps_DO_NOT_USE } from '@atlassian/embedded-confluence/useReactProps';

import { useStringFeatureFlag } from '@confluence/session-data';

const ALL_PARENT_PRODUCTS = 'ALL_PARENT_PRODUCTS';
const NO_PARENT_PRODUCTS = 'NO_PARENT_PRODUCTS';
const ENABLE_MIGRATION_FF_ON_LOAD = 'confluence.frontend.embedded-confluence.enable.migration';
const ENABLE_MIGRATION_FF_ON_EDIT =
	'confluence.frontend.embedded-confluence.enable.migration.on.edit';

export type Result = {
	isEPMigrationOnLoad: boolean;
	isEPMigrationOnEdit: boolean;
};

export function useIsEmbeddedConfluenceMigrationEnabled(): Result {
	const allowedParentProductsOnLoad = useStringFeatureFlag(ENABLE_MIGRATION_FF_ON_LOAD);
	const allowedParentProductsOnEdit = useStringFeatureFlag(ENABLE_MIGRATION_FF_ON_EDIT);

	const isEPMigrationOnLoad = useIsMigrationEnabledFor(allowedParentProductsOnLoad);
	const isEPMigrationOnEdit = useIsMigrationEnabledFor(allowedParentProductsOnEdit);

	// When both flags are true, onEdit will take precedent
	return isEPMigrationOnLoad && isEPMigrationOnEdit
		? { isEPMigrationOnLoad: false, isEPMigrationOnEdit: true }
		: { isEPMigrationOnLoad, isEPMigrationOnEdit };
}

// Returns true when inside Parent Product and FF is enabled for that Parent Product
function useIsMigrationEnabledFor(allowedParentProducts): boolean {
	const { parentProduct } = useReactProps_DO_NOT_USE<IframePassThroughProps>() || {};

	if (!parentProduct || !allowedParentProducts) return false;

	if (allowedParentProducts === ALL_PARENT_PRODUCTS) {
		return true;
	} else if (allowedParentProducts === NO_PARENT_PRODUCTS) {
		return false;
	} else {
		return allowedParentProducts
			.split(',')
			.some((x) => x.toUpperCase() === parentProduct.toUpperCase());
	}
}
