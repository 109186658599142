import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';

const i18n = defineMessages({
	aiMenuAriaText: {
		id: 'ai-content-actions.popup-trigger.aria-text',
		defaultMessage: 'AI Actions Menu',
		description: 'Aria label for the AI actions menu which has no visible text',
	},
});

export const AIContentActionsMenuPopupPlaceholder = () => {
	const intl = useIntl();

	return (
		<IconButton
			testId="ai-content-actions-menu-button-placeholder"
			appearance="subtle"
			isSelected={false}
			isDisabled
			icon={ChevronDownIcon}
			label={intl.formatMessage(i18n.aiMenuAriaText)}
		/>
	);
};
