import React from 'react';

import { SplitButton } from '@atlaskit/button/new';

import { AISummarizeButtonPlaceholder } from '@atlassian/ai-summary';

import { AIContentActionsMenuPopupPlaceholder } from './AIContentActionsMenuPopupPlaceholder';

export const AIContentActionsMenuSplitButtonPlaceholder = () => {
	return (
		<SplitButton>
			<AISummarizeButtonPlaceholder />
			<AIContentActionsMenuPopupPlaceholder />
		</SplitButton>
	);
};
