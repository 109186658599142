import { useBooleanFeatureFlag } from '@confluence/session-data';

import { COVERPICTUREWIDTH } from './contentTopperConstants';

export const useIsFixedWidthImageOption = ({
	coverPictureWidth,
}: {
	coverPictureWidth?: COVERPICTUREWIDTH;
}) => {
	const isFixedWidthToggleFFOn = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.cover-image-width-toggle',
	);
	const isForceFixedWidthOn = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.fixed-width-header-image-option',
	);
	const isFixedWidthImageOption =
		isForceFixedWidthOn ||
		(isFixedWidthToggleFFOn && coverPictureWidth === COVERPICTUREWIDTH.FIXED);

	return isFixedWidthImageOption;
};
