import type { FC } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import type { ReactComponentLike } from 'prop-types';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';

import { VIEW_PAGE_TITLE_EXPERIENCE, ExperienceSuccess } from '@confluence/experience-tracker';
import { h800 } from '@confluence/typography-placeholder';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { LazyEmojiComponentLoader } from '@confluence/emoji-title';
import {
	EMOJI_SIZE_LARGE,
	EMOJI_SIZE_MEDIUM,
} from '@confluence/emoji-title/entry-points/constants';
import { ContentClassificationTag } from '@confluence/data-classification';
import {
	TitleAlignmentType,
	useTitleContentPropertiesForPublishedPage,
	useIsFixedWidthImageOption,
	type TitleContentPropertiesType,
} from '@confluence/custom-sites-extensions';
import { CopyLinkButtonLoader } from '@confluence/share';
import { useContentClassification } from '@confluence/data-classification/entry-points/queries';

import { CONTENT_TITLE_METRIC } from '../perf.config';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const HeadingWrapper = styled.div<{
	isCustomSitesPageTitleFFOn?: boolean;
	hasBanner?: boolean;
}>`
	position: ${({ isCustomSitesPageTitleFFOn }) =>
		isCustomSitesPageTitleFFOn ? 'relative' : 'unset'};
	margin-bottom: ${({ isCustomSitesPageTitleFFOn, hasBanner }) =>
		isCustomSitesPageTitleFFOn && hasBanner ? token('space.300', '24px') : 'initial'};
`;

// TODO: Refactor to a common location
// See https://product-fabric.atlassian.net/browse/CFE-2847

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const StyledContentTitle = styled.div<{
	centerTitle: boolean;
}>`
	/* please update next/packages/content-title/src/ContentTitlePlaceholder.js if you change anything here */
	display: inline-block;
	box-sizing: border-box;
	min-height: 120px;
	width: 100%;
	max-width: 100%;

	${({ centerTitle }) =>
		centerTitle
			? `
    display: block;
    margin: 0 auto;
    word-break: break-word;
    word-wrap: break-word;
    max-width: 760px;
    `
			: ''};
`;
StyledContentTitle.displayName = 'StyledContentTitle';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const StyledH1 = styled.h1<{
	onClick: React.ComponentProps<'h1'>['onClick'];
	isTitleCenterAligned?: boolean;
	hasCoverPicture?: boolean;
	isPageContentFullWidth?: boolean;
	isCustomSitesPageTitleFFOn?: boolean;
	isEmbeddedPage?: boolean;
	hasEmoji?: boolean;
	isFixedWidthImageOption?: boolean;
}>`
	&#title-text {
		align-items: ${({ isCustomSitesPageTitleFFOn }) =>
			isCustomSitesPageTitleFFOn ? 'center' : 'initial'};
		color: ${token('color.text', h800)};
		font-weight: normal;
		flex-direction: ${({
			isFixedWidthImageOption,
			isCustomSitesPageTitleFFOn,
			hasEmoji,
			isPageContentFullWidth,
			hasCoverPicture,
			isTitleCenterAligned,
		}) =>
			(isTitleCenterAligned && isCustomSitesPageTitleFFOn) ||
			(isFixedWidthImageOption &&
				hasEmoji &&
				!isTitleCenterAligned &&
				isCustomSitesPageTitleFFOn &&
				isPageContentFullWidth &&
				hasCoverPicture)
				? 'column'
				: 'unset'};
		margin-top: ${({ isCustomSitesPageTitleFFOn, isEmbeddedPage, hasCoverPicture }) =>
			isCustomSitesPageTitleFFOn && isEmbeddedPage && hasCoverPicture
				? token('space.negative.300', '-24px')
				: '0'};
		font-size: 28px;
		line-height: 1.25;
		gap: ${({ isTitleCenterAligned, hasCoverPicture }) =>
			isTitleCenterAligned && !hasCoverPicture ? token('space.200', '16px') : 'initial'};
	}
	margin: 0;
	color: ${token('color.text', '#333')};
	word-break: break-word;
	word-wrap: break-word;
	white-space: preserve;
	/* update next/packages/content-title/src/ContentTitlePlaceholder.js when this changes */
	min-height: 35px;
	padding-top: 5px;
	cursor: pointer;
	display: flex;
	pointer-events: ${({ onClick }) => (onClick ? 'default' : 'none')};
	${({ isTitleCenterAligned }) =>
		isTitleCenterAligned
			? 'justify-content: center; text-align: center; flex-direction: column; align-items: center'
			: ''}
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const EmojiFullWidthLeftAlignWrapper = styled.div`
	display: flex;
	justify-content: initial;
	width: 800px;
	padding-left: 40px;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const TitleTextFullWidthLeftAlignWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 100%;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const EmojiWrapper = styled.div<{
	isCustomSitesPageTitleFFOn: boolean;
	isTitleCenterAligned?: boolean;
	hasCoverPicture?: boolean;
}>`
	padding-right: ${({ isTitleCenterAligned }) => (isTitleCenterAligned ? '0px' : '10px')};
	display: flex;
	justify-content: center;
	max-width: ${({ isCustomSitesPageTitleFFOn, hasCoverPicture }) =>
		isCustomSitesPageTitleFFOn
			? hasCoverPicture
				? `${EMOJI_SIZE_LARGE}px`
				: `${EMOJI_SIZE_MEDIUM}px`
			: '26px'};
	align-items: ${({ isTitleCenterAligned }) => (isTitleCenterAligned ? 'initial' : 'center')};
	position: ${({ isCustomSitesPageTitleFFOn, hasCoverPicture }) =>
		isCustomSitesPageTitleFFOn && hasCoverPicture ? 'absolute' : 'unset'};
	top: ${({ isCustomSitesPageTitleFFOn, hasCoverPicture }) =>
		isCustomSitesPageTitleFFOn && hasCoverPicture ? '-75px' : 'unset'};

	> * span {
		${({ isCustomSitesPageTitleFFOn }) => isCustomSitesPageTitleFFOn && 'display: flex'};
		justify-content: ${({ isCustomSitesPageTitleFFOn }) =>
			isCustomSitesPageTitleFFOn ? 'center' : 'initial'};
		width: ${({ isCustomSitesPageTitleFFOn, hasCoverPicture }) =>
			isCustomSitesPageTitleFFOn
				? hasCoverPicture
					? `${EMOJI_SIZE_LARGE}px`
					: `${EMOJI_SIZE_MEDIUM}px`
				: '26px'};
		height: ${({ isCustomSitesPageTitleFFOn, hasCoverPicture }) =>
			isCustomSitesPageTitleFFOn
				? hasCoverPicture
					? `${EMOJI_SIZE_LARGE}px`
					: `${EMOJI_SIZE_MEDIUM}px`
				: '26px'};
		overflow: ${({ isCustomSitesPageTitleFFOn }) =>
			isCustomSitesPageTitleFFOn ? 'hidden' : 'initial'};
		align-items: ${({ isCustomSitesPageTitleFFOn }) =>
			isCustomSitesPageTitleFFOn ? 'center' : 'initial'};
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const TitleTextWrapper = styled.span<{
	isTitleCenterAligned?: boolean;
	hasEmojiAndCoverImage?: boolean;
}>`
	margin-top: ${({ isTitleCenterAligned, hasEmojiAndCoverImage }) =>
		hasEmojiAndCoverImage ? '26px' : isTitleCenterAligned ? '-5px' : 'unset'};

	/* Slide out and show "copy link" button next to title on hover */
	&:hover > span,
	&:focus-within > span {
		opacity: 1;
		transform: none;
		transform-duration: 0.1s;
	}
`;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const CopyLinkWrapper = styled.span<{}>`
	font-size: 15px;
	/* The following styles hide Copy Link button initially */
	margin-inline-start: 0.5rem;
	opacity: 0;
	position: absolute;
	transform: translateX(-4px);
	transition:
		opacity 0.2s ease-out 0s,
		transform 0.2s ease-out 0s;
`;

StyledH1.displayName = 'StyledH1';

type ContentTitleProps = {
	banner?: React.ReactNode | null;
	centerTitle: boolean;
	lookAndFeel?: {
		content: {
			header: any;
		};
		headings: any;
	};
	prefix?: string | '';
	title?: string | '';
	children?: React.ReactNode | null;
	hasEmoji: boolean;
	emojiId: string | '';
	contentId: string;
	contentType: string;
	spaceKey: string;
	loading: boolean;
	headingContainer?:
		| React.ComponentType<{
				children: React.ReactNode;
				showInlineCommentButton?: boolean;
		  }>
		| ReactComponentLike;
	hasCoverPicture?: boolean;
	isPageContentFullWidth?: boolean;
	isEmbeddedPage?: boolean;
	titleContentProperties: TitleContentPropertiesType;
	isCustomSitesPageTitleFFOn: boolean;
};

type NullableQueryParams = {
	param: string | string[] | null;
};

const isURLVisible = () => window.top === window.self;

export const ContentTitleComponent: FC<ContentTitleProps> = ({
	lookAndFeel,
	banner,
	title,
	children,
	centerTitle,
	prefix,
	hasEmoji,
	emojiId,
	contentId,
	spaceKey,
	contentType,
	loading,
	headingContainer: HeadingContainer = React.Fragment,
	hasCoverPicture,
	isPageContentFullWidth,
	isEmbeddedPage,
	titleContentProperties,
	isCustomSitesPageTitleFFOn,
}) => {
	const [emojiTitle, setEmojiTitle] = useState<string | null>(emojiId);
	useEffect(() => {
		// for page that does not have emoji, we want to set emoji to null
		// so the emoji doesn't appear twice when user navigate between page via page tree
		if (!hasEmoji) {
			setEmojiTitle(null);
		} else {
			setEmojiTitle(emojiId);
		}
	}, [hasEmoji, emojiId]);

	const { getQueryParams, setQueryParams } = useContext(RoutesContext);

	const { coverPictureWidth } = useTitleContentPropertiesForPublishedPage({
		contentId,
		isCustomSitesPageTitleFFOn,
	});
	const isFixedWidthImageOption = useIsFixedWidthImageOption({ coverPictureWidth });

	const isTitleCenterAligned =
		isCustomSitesPageTitleFFOn &&
		titleContentProperties?.titleLayoutAlignment == TitleAlignmentType.CENTER;

	/**
	 * The feature of clearing query params by clicking title is only useful when it's used in standalone Confluence with the URL visible.
	 * When a page gets embedded e.g. rendered within iframe macro, user won't be able to see the effect from clearing query params and the mouse pointer and clicking lead to a confusing user experience.
	 */
	const clearQueryParams = isURLVisible()
		? () => {
				const queryParams = getQueryParams() as NullableQueryParams;
				if (!queryParams) return;
				Object.keys(queryParams).forEach((k) => (queryParams[k] = null));
				setQueryParams(queryParams);
			}
		: undefined;

	const contentClassificationMetadata = useContentClassification({
		contentId,
		spaceKeyOverride: spaceKey,
		contentStatusContext: 'current-only',
	});

	const isLeftAlignedTitleWithFixedWidthImageAndEmoji =
		isFixedWidthImageOption &&
		hasEmoji &&
		!isTitleCenterAligned &&
		hasCoverPicture &&
		isPageContentFullWidth &&
		isCustomSitesPageTitleFFOn;

	const heading = (
		<StyledH1
			id="title-text"
			data-test-id="title-text"
			data-testid="title-text"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={lookAndFeel?.headings}
			onClick={clearQueryParams}
			isTitleCenterAligned={isTitleCenterAligned}
			hasCoverPicture={hasCoverPicture}
			isPageContentFullWidth={isPageContentFullWidth}
			isCustomSitesPageTitleFFOn={isCustomSitesPageTitleFFOn}
			isFixedWidthImageOption={isFixedWidthImageOption}
			isEmbeddedPage={isEmbeddedPage}
			hasEmoji={hasEmoji}
		>
			{emojiTitle && (
				<>
					{isLeftAlignedTitleWithFixedWidthImageAndEmoji ? (
						<EmojiFullWidthLeftAlignWrapper>
							<EmojiWrapper
								isCustomSitesPageTitleFFOn={isCustomSitesPageTitleFFOn}
								isTitleCenterAligned={isTitleCenterAligned}
								hasCoverPicture={hasCoverPicture}
							>
								<span data-testid="emoji-title">
									<LazyEmojiComponentLoader
										emoji={emojiTitle}
										height={
											isCustomSitesPageTitleFFOn
												? hasCoverPicture
													? EMOJI_SIZE_LARGE
													: EMOJI_SIZE_MEDIUM
												: 26
										}
										hasCoverPicture={hasCoverPicture}
										context="viewPage"
										showTooltip
										renderResourcedEmoji={isCustomSitesPageTitleFFOn}
										isPageTitleComponent
									/>
								</span>
							</EmojiWrapper>
						</EmojiFullWidthLeftAlignWrapper>
					) : (
						<EmojiWrapper
							isCustomSitesPageTitleFFOn={isCustomSitesPageTitleFFOn}
							isTitleCenterAligned={isTitleCenterAligned}
							hasCoverPicture={hasCoverPicture}
						>
							<span data-testid="emoji-title">
								<LazyEmojiComponentLoader
									emoji={emojiTitle}
									height={
										isCustomSitesPageTitleFFOn
											? hasCoverPicture
												? EMOJI_SIZE_LARGE
												: EMOJI_SIZE_MEDIUM
											: 26
									}
									hasCoverPicture={hasCoverPicture}
									context="viewPage"
									showTooltip
									renderResourcedEmoji={isCustomSitesPageTitleFFOn}
									isPageTitleComponent
								/>
							</span>
						</EmojiWrapper>
					)}
				</>
			)}
			{isLeftAlignedTitleWithFixedWidthImageAndEmoji ? (
				<TitleTextFullWidthLeftAlignWrapper>
					<TitleTextWrapper
						isTitleCenterAligned={isTitleCenterAligned}
						hasEmojiAndCoverImage={hasCoverPicture && isCustomSitesPageTitleFFOn}
					>
						{prefix ? `${prefix} - ${title}` : title}
						<CopyLinkWrapper data-testId="copylink-wrapper">
							<CopyLinkButtonLoader
								shouldResetOnHover
								contentId={contentId}
								spaceKey={spaceKey}
								contentType={contentType}
								pageMode="view"
								source="pageTitle"
								buttonAppearance="subtle-link"
								tooltipPosition="top"
							/>
						</CopyLinkWrapper>
					</TitleTextWrapper>
				</TitleTextFullWidthLeftAlignWrapper>
			) : (
				<TitleTextWrapper
					isTitleCenterAligned={isTitleCenterAligned}
					hasEmojiAndCoverImage={hasCoverPicture && isCustomSitesPageTitleFFOn}
				>
					{prefix ? `${prefix} - ${title}` : title}
					<CopyLinkWrapper data-testId="copylink-wrapper">
						<CopyLinkButtonLoader
							shouldResetOnHover
							contentId={contentId}
							spaceKey={spaceKey}
							contentType={contentType}
							pageMode="view"
							source="pageTitle"
							buttonAppearance="subtle-link"
							tooltipPosition="top"
						/>
					</CopyLinkWrapper>
				</TitleTextWrapper>
			)}
		</StyledH1>
	);

	return (
		<div data-testid="title-wrapper">
			<StyledContentTitle
				centerTitle={centerTitle}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					...lookAndFeel?.content?.header,
				}}
				data-test-id="page-content-header"
				data-testid="page-content-header"
			>
				{(!isCustomSitesPageTitleFFOn && banner) || null}
				{!isCustomSitesPageTitleFFOn && (
					<ContentClassificationTag
						contentId={contentId}
						contentType={contentType}
						contentClassificationMetadata={contentClassificationMetadata}
						isEditMode={false}
					/>
				)}
				<HeadingWrapper
					isCustomSitesPageTitleFFOn={isCustomSitesPageTitleFFOn}
					hasBanner={!!banner}
				>
					<HeadingContainer showInlineCommentButton>{heading}</HeadingContainer>
				</HeadingWrapper>
				{(isCustomSitesPageTitleFFOn && banner) || null}
				{!loading && <PageSegmentLoadEnd key={contentId} metric={CONTENT_TITLE_METRIC} />}
				{children}
				<ExperienceSuccess name={VIEW_PAGE_TITLE_EXPERIENCE} />
			</StyledContentTitle>
		</div>
	);
};
