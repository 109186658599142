import { getLogger } from '@confluence/logger';

const logger = getLogger('scroll-to-element');

export const PAGE_HEADER_HEIGHT = 60;

const explicitScrollListeners = new Set<() => void>();
export function addExplicitScrollListener(listener: () => void): () => void {
	explicitScrollListeners.add(listener);

	return () => explicitScrollListeners.delete(listener);
}

function invokeExplicitScrollListeners() {
	explicitScrollListeners.forEach((listener) => {
		try {
			listener();
		} catch (error) {
			Promise.all([
				import(/* webpackChunkName: "loadable-confluencemonitoring" */ '@confluence/monitoring'),
				import(
					/* webpackChunkName: "loadable-confluenceerror-boundary" */ '@confluence/error-boundary'
				),
			])
				.then(([{ getMonitoringClient }, { Attribution }]) => {
					getMonitoringClient().submitError(error, {
						attribution: Attribution.PAGE_EXTENSIONS,
					});
				})
				.catch(() => {
					// ignore errors, we can't do anything at this point
				});
		}
	});
}

function isSmoothScrollSupported() {
	return 'scrollBehavior' in document.documentElement.style;
}

export function scrollToPageStart(isSmooth: boolean = false) {
	invokeExplicitScrollListeners();

	if (isSmooth && isSmoothScrollSupported()) {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	} else {
		window.scrollTo(0, 0);
	}
}

/**
 * Scrolls element into browser view, taking various offsets into account.
 *
 * @param element - the event which you want to scroll into view
 * @param scrollCorrectionPixels - the amount of pixels the scroll position needs to be adjusted for. For example,
 * if you have a fixed-positioned element that overlaps your scroll area,
 * and that element has a height of `60` pixels, you'll set `scrollCorrectionPixels` to `-60`.
 */
export function scrollToElement(
	element: HTMLElement,
	scrollCorrectionPixels?: number,
	scrollBehavior: ScrollBehavior = 'auto',
): void {
	let parentsOffset: number = 0;
	let currentOffsetParent = element.offsetParent;
	while (currentOffsetParent instanceof HTMLElement) {
		parentsOffset += currentOffsetParent.offsetTop;
		currentOffsetParent = currentOffsetParent.offsetParent;
	}

	let top = element.offsetTop + (scrollCorrectionPixels || 0) + parentsOffset - PAGE_HEADER_HEIGHT;
	// check if element is nested inside table, if so we need to account for
	// sticky table header overlapping the element
	const hasTableParent = element?.closest('.pm-table-container td');
	if (hasTableParent && scrollCorrectionPixels) top = top + scrollCorrectionPixels * 1.25;

	invokeExplicitScrollListeners();

	let behavior = scrollBehavior;
	if (behavior === 'smooth' && !isSmoothScrollSupported()) {
		behavior = 'auto';
	}

	try {
		window.scroll({
			top,
			behavior,
		});
	} catch (err) {
		logger.error`window.scroll() is not supported`;
	}
}
