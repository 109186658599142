import { useEffect } from 'react';

import { useRouteName } from '@confluence/route-manager';
import { VIEW_PAGE, VIEW_BLOG, VIEW_BLOG_DATE_LEGACY } from '@confluence/named-routes';
import { useConvertPageToFolderStore } from '@confluence/folder/entry-points/useConvertPageToFolderStore';
import type { FloatingBannerStateContainer } from '@confluence/banners';

import { useViewPageDistinctionBannerStore } from './ViewPageDistinctionBannerStore';
import { BANNER_HEIGHT } from './ViewPageDistinctionBanner';

type UseShouldShowViewPageDistinctionBannerProps = {
	contentId: string;
	spaceKey: string;
	versionNumber: number | null | undefined;
	bannerState: FloatingBannerStateContainer;
	waitForFolderBanner: boolean;
};

type UseShouldShowViewPageDistinctionBannerReturn = {
	isBannerVisible: boolean;
};

export const useViewPageDistinctionBannerVisibilityState = ({
	contentId,
	spaceKey,
	versionNumber,
	bannerState,
	waitForFolderBanner,
}: UseShouldShowViewPageDistinctionBannerProps): UseShouldShowViewPageDistinctionBannerReturn => {
	const [, { setViewPageBannerVisibility }] = useViewPageDistinctionBannerStore();
	const [{ showConvertPageToFolderBanner, convertPageToFolderBannerLoaded }] =
		useConvertPageToFolderStore();
	const isOnPageWhereViewPageBannerCanBeShown = useRouteName({
		selector: (routeName: string | undefined) =>
			routeName === VIEW_PAGE.name ||
			routeName === VIEW_BLOG.name ||
			routeName === VIEW_BLOG_DATE_LEGACY.name,
	});

	const isBannerVisible =
		isOnPageWhereViewPageBannerCanBeShown &&
		(!waitForFolderBanner || convertPageToFolderBannerLoaded) &&
		!showConvertPageToFolderBanner &&
		!!contentId &&
		!!spaceKey &&
		!!versionNumber;

	useEffect(() => {
		setViewPageBannerVisibility(isBannerVisible);

		if (isBannerVisible) {
			bannerState.showFloating('view-page-distinction-banner', BANNER_HEIGHT);
		} else {
			bannerState.hideFloating('view-page-distinction-banner');
		}
	}, [setViewPageBannerVisibility, bannerState, isBannerVisible]);

	useEffect(() => {
		return () => {
			setViewPageBannerVisibility(false);
			bannerState.hideFloating('view-page-distinction-banner');
		};
	}, [setViewPageBannerVisibility, bannerState]);

	return { isBannerVisible };
};
