import { createStore, createHook } from 'react-sweet-state';

type State = {
	showConvertPageToFolderBanner: boolean;
	convertPageToFolderBannerLoaded: boolean;
};

const initialState: State = {
	showConvertPageToFolderBanner: false,
	convertPageToFolderBannerLoaded: false,
};
export const actions = {
	showBanner:
		() =>
		({ setState }: { setState: any }) => {
			setState({ showConvertPageToFolderBanner: true });
		},
	hideBanner:
		() =>
		({ setState }: { setState: any }) => {
			setState({ showConvertPageToFolderBanner: false });
		},
	markBannerLoaded:
		(hasLoaded: boolean) =>
		({ setState }: { setState: any }) => {
			setState({ convertPageToFolderBannerLoaded: hasLoaded });
		},
};

export const Store = createStore({
	initialState,
	actions,
	name: 'convertPageToFolderStore',
});

export const useConvertPageToFolderStore = createHook(Store);
