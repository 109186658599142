import { LoadableAfterPaint } from '@confluence/loadable';

import { CommentButtonPlaceholder } from './CommentButtonPlaceholder';

export { CommentButtonQuery } from './CommentButtonQuery.graphql';

export const CommentButton = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CommentButton" */ './CommentButton'))
			.CommentButton,
	loading: CommentButtonPlaceholder as any,
});

export { CommentButtonWrapper } from './CommentButtonWrapper';

export { inlineCommentGifContent, getPlacement } from './CommentButton';
export { CommentIconLoadable } from './CommentIconLoadable';
