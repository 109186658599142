import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import { isExpectedApolloError } from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';
import { markErrorAsHandled } from '@confluence/graphql';
import { TOOLS_MENU_CONTAINER } from '@confluence/web-item-location';

import type {
	ConvertPageToFolderQuery as ConvertPageToFolderQueryType,
	ConvertPageToFolderQueryVariables,
} from './__types__/ConvertPageToFolderQuery';
import { ConvertPageToFolderQuery } from './ConvertPageToFolderQuery.graphql';

export const CONVERT_PAGE_TO_FOLDER_WEBITEM_ID = 'action-convert-page-to-folder';

export const useCanConvertPageToFolder = ({
	contentId,
	spaceKey,
	skipQuery,
}: {
	contentId: string;
	spaceKey: string;
	skipQuery?: boolean;
}) => {
	const skip = skipQuery || !fg('confluence_enable_page_to_folder_conversion');
	const { data, loading, error } = useQuery<
		ConvertPageToFolderQueryType,
		ConvertPageToFolderQueryVariables
	>(ConvertPageToFolderQuery, {
		skip,
		variables: { contentId, spaceKey, location: TOOLS_MENU_CONTAINER },
		returnPartialData: true,
		onError: (error) => {
			if (isExpectedApolloError(error)) {
				markErrorAsHandled(error);
			}
		},
	});

	const isFoldersEnabledForSpace =
		data?.space?.spaceTypeSettings?.enabledContentTypes?.isFoldersEnabled;

	const contentTitle = data?.content?.nodes?.[0]?.title;

	// We are querying both currentPage and ptpage to always fetch data from cache. The currentPage is cached by PageTreeQuery on
	// first page tree load, while ptpage is cached by PageTreeChildrenQuery when clicking on a parent page in the page tree
	const currentPageQueryHasChildren = (data?.currentPage?.children?.nodes?.length || 0) > 0;
	const ptpageQueryHasChildren = (data?.ptpage?.children?.nodes?.length || 0) > 0;
	const isParent = currentPageQueryHasChildren || ptpageQueryHasChildren;

	// Web item only exists if the content is "page" type and user has view + edit permissions on the page
	const modifySectionWebItems = data?.webItemSections?.find(
		(item: any) => item.label === 'content.actions.modify',
	)?.items;
	const hasFolderConversionWebItem = Boolean(
		modifySectionWebItems?.find((item: any) => item.id === CONVERT_PAGE_TO_FOLDER_WEBITEM_ID),
	);

	const canConvertPageToFolder = Boolean(
		isFoldersEnabledForSpace && isParent && hasFolderConversionWebItem,
	);

	const hasLoaded = evalHasLoaded(skip, data, error, loading);

	return { canConvertPageToFolder, contentTitle, loading, error, hasLoaded };
};

export const evalHasLoaded = (
	skip: boolean,
	data: ConvertPageToFolderQueryType | undefined,
	error: ApolloError | undefined,
	loading: boolean,
) => {
	return skip || ((!!data || !!error) && !loading);
};
