import type { ComponentProps } from 'react';
import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';

import Spinner from '@atlaskit/spinner/spinner';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { VIEW_PAGE_EDIT_BUTTON_EXPERIENCE, ExperienceStart } from '@confluence/experience-tracker';
import { SSRMouseEventWrapper, SSR_EDIT_BUTTON_METRIC } from '@confluence/browser-metrics';
import { Attribution, ErrorDisplay, withErrorBoundary } from '@confluence/error-boundary';
import { CollabServiceProperty } from '@confluence/native-collab';

import { useIsEmbeddedConfluenceMigrationEnabled } from './useIsEmbeddedConfluenceMigrationEnabled';
import { EditContentButtonComponent } from './EditContentButtonComponent';
import { EditButtonQuery } from './EditButtonQuery.graphql';
import { useEmbeddedMigrationQuery } from './useEmbeddedMigrationQuery';
import type {
	EditButtonQuery as EditButtonQueryType,
	EditButtonQueryVariables,
} from './__types__/EditButtonQuery';

type EditContentButtonProps = Pick<
	ComponentProps<typeof EditContentButtonComponent>,
	'onRender'
> & {
	contentId: string;
	isCompanyHubButton?: boolean;
};

const EditContentButtonInner: React.FC<EditContentButtonProps> = ({
	contentId,
	isCompanyHubButton,
	...passThroughProps
}) => {
	const { isEPMigrationOnLoad, isEPMigrationOnEdit } = useIsEmbeddedConfluenceMigrationEnabled();
	const { epMigrationOnLoad, epMigrationOnClick } = useEmbeddedMigrationQuery(
		contentId,
		!isEPMigrationOnLoad,
	);

	const { data, loading, error } = useQuery<EditButtonQueryType, EditButtonQueryVariables>(
		EditButtonQuery,
		{
			variables: {
				contentId,
			},
			fetchPolicy: 'cache-and-network',
		},
	);

	const {
		data: epMigrationOnLoadData,
		loading: epMigrationOnLoadLoading,
		error: epMigrationOnLoadError,
	} = epMigrationOnLoad;

	const {
		migrateEPData,
		data: epMigrationOnClickData,
		loading: epMigrationOnClickLoading,
		error: epMigrationOnClickError,
	} = epMigrationOnClick;

	let renderedEditContentButton: React.ReactNode;

	if (error) {
		renderedEditContentButton = <ErrorDisplay error={error} />;
	} else if (
		(!data && loading) ||
		(!epMigrationOnLoadData && epMigrationOnLoadLoading) ||
		(!epMigrationOnClickData && epMigrationOnClickLoading)
	) {
		renderedEditContentButton = <Spinner size="small" />;
	} else {
		const contentNode = data?.content?.nodes?.[0];
		const operations = contentNode?.operations ?? [];
		const contentType = contentNode?.type ?? undefined;

		const canEdit =
			operations.filter(
				(operationCheckResult) =>
					operationCheckResult?.operation === 'update' &&
					operationCheckResult?.targetType === contentType,
			).length > 0;

		if (!canEdit) {
			return null;
		}
		const spaceKey = data?.content?.nodes?.[0]?.space?.key ?? undefined;

		const metadataFrontend = data?.content?.nodes?.[0]?.metadata?.frontend;
		const isFabricSupported = metadataFrontend?.fabricEditorSupported ?? false;
		const isNCSContent = metadataFrontend?.collabService === CollabServiceProperty.NCS;

		let isEmbeddedContent;
		// 'edit-embed' route (embedded editor) should only be used inside parent product and when page is ncs
		if (isEPMigrationOnLoad) {
			isEmbeddedContent =
				epMigrationOnLoadData?.content?.nodes?.[0]?.metadata?.frontend?.embeddedWithMigration ??
				false;
		} else {
			isEmbeddedContent = (isEmbeddedConfluence_DO_NOT_USE() && isNCSContent) ?? false;
		}

		const embeddedMigrationError = epMigrationOnLoadError || epMigrationOnClickError;
		return (
			<>
				{
					/* Edit button should not break or fail to load if non-essential embedded query fails as we also
           have redirection to edit-embed within EditPage Loader (i.e. edit-v2 route */
					embeddedMigrationError && <ErrorDisplay error={embeddedMigrationError} />
				}
				<SSRMouseEventWrapper metricName={SSR_EDIT_BUTTON_METRIC}>
					<EditContentButtonComponent
						contentId={contentId}
						contentType={contentType}
						spaceKey={spaceKey}
						isCompanyHubButton={isCompanyHubButton}
						isFabricSupported={isFabricSupported}
						isEmbeddedContent={isEmbeddedContent}
						migrateEPData={isEPMigrationOnEdit && !isNCSContent ? migrateEPData : undefined}
						{...passThroughProps}
					/>
				</SSRMouseEventWrapper>
			</>
		);
	}

	return (
		<Fragment>
			<ExperienceStart id={contentId} name={VIEW_PAGE_EDIT_BUTTON_EXPERIENCE} />
			<SSRMouseEventWrapper metricName={SSR_EDIT_BUTTON_METRIC}>
				{renderedEditContentButton}
			</SSRMouseEventWrapper>
		</Fragment>
	);
};

export const EditContentButton: React.FC<EditContentButtonProps> = memo(
	withErrorBoundary({
		attribution: Attribution.BACKBONE,
	})(EditContentButtonInner),
);

EditContentButton.propTypes = {
	contentId: PropTypes.string.isRequired,
	isCompanyHubButton: PropTypes.bool,
	onRender: EditContentButtonComponent.propTypes?.onRender,
};
