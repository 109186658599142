import gql from 'graphql-tag';

export const ConvertPageToFolderQuery = gql`
	query ConvertPageToFolderQuery($contentId: ID!, $spaceKey: String!, $location: String) {
		content(id: $contentId) {
			nodes {
				id
				title
			}
		}
		currentPage: ptpage(
			id: $contentId
			spaceKey: $spaceKey
			pageTree: 160
			status: [CURRENT, DRAFT]
			enablePaging: true
		) {
			id
			children {
				nodes {
					id
				}
			}
		}
		ptpage(id: $contentId, status: [CURRENT, DRAFT]) {
			id
			children(first: 100) {
				nodes {
					id
				}
			}
		}
		space(key: $spaceKey) {
			id
			spaceTypeSettings {
				enabledContentTypes {
					isFoldersEnabled
				}
			}
		}
		webItemSections(
			location: $location
			locations: []
			contentId: $contentId
			key: null
			version: null
		) {
			label
			items {
				id
			}
		}
	}
`;
