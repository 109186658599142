import type { FC } from 'react';
import React, { memo, useMemo, useCallback, useContext, Fragment } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { N200 } from '@atlaskit/theme/colors';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import AkAvatarGroup from '@atlaskit/avatar-group';

import { VIEW_PAGE_BYLINE_EXPERIENCE, ExperienceSuccess } from '@confluence/experience-tracker';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { ProfileAvatar, ProfileLink } from '@confluence/profile';
import { ReadTime } from '@confluence/read-time';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { AnalyticsByLine } from '@confluence/confluence-analytics';
import { ExternalShareContext } from '@confluence/external-share-context';
import { scrollPageCommentIntoView } from '@confluence/comments-util';
import { PoweredByTemplates } from '@confluence/powered-by-templates';
import { PageOwnershipByline } from '@confluence/content-ownership';
import { AutoConversionByLine } from '@confluence/editor-conversion/entry-points/AutoConversionByLine';
import {
	TitleAlignmentType,
	type TitleContentPropertiesType,
} from '@confluence/custom-sites-extensions';
import { CUSTOM_SITES_PAGE_TITLE_FF } from '@confluence/emoji-title/entry-points/constants';

import { ByLineApps } from './ByLineApps';
import { ByLineLastModified } from './ByLineLastModified';
import type { UserShape } from './types';
import { getArrangedContributors } from './utils';
import { png_1x1_n40 } from './png1x1';
import {
	StyledMainDiv,
	StyledAuthorDiv,
	StyledAvatars,
	StyledNameSkeleton,
	StyledTextDiv,
	CommentCountContainer,
} from './PresentationalComponents';

const NO_OF_AVATARS = 4;

const BLOG_POST_SOURCE_TEMPLATE_ENTITY_ID = '00000000-0000-0000-0000-000000000001';

type version = {
	friendlyWhen: string;
	number: number;
	by: UserShape;
	contentTypeModified: boolean;
};

type ByLineProps = {
	contentId: string;
	author: UserShape;
	owner: UserShape | null;
	lastOwner: UserShape | null;
	createdDate: Date | null;
	commentCount: number;
	version: version;
	contributors: UserShape[];
	contributorsCount: number;
	fetchAllContributors: Function;
	versionComment?: string;
	hasByLineContributors?: boolean;
	hasByLineExtensions?: boolean;
	templateId: string | null;
	isFabricPage: boolean;
	titleContentProperties: TitleContentPropertiesType;
};

type userAvatarShape = {
	isAnonymous: boolean;
	userId: string;
	src: string;
	name: string;
};

const i18n = defineMessages({
	commentCount: {
		id: 'content-header.by-line.comment.count',
		defaultMessage: '{count, plural, one {# page comment} other {# page comments}}',
	},
});

export const ByLineWithContributorsComponent: FC<ByLineProps> = memo(
	({
		contentId,
		author,
		owner,
		createdDate,
		commentCount,
		version,
		versionComment,
		contributors,
		contributorsCount,
		fetchAllContributors,
		hasByLineContributors,
		hasByLineExtensions,
		templateId,
		isFabricPage,
		lastOwner,
		titleContentProperties,
	}) => {
		const isPageCommentNavigationCTAEnabled =
			FeatureGates.getExperimentValue<'control' | 'variation'>(
				'cc-page-comments-quick-navigation',
				'cohort',
				'control',
			) !== 'control';
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { isExternalShareRequest } = useContext(ExternalShareContext);
		const isCustomSitesPageTitleFFOn = useBooleanFeatureFlag(CUSTOM_SITES_PAGE_TITLE_FF);

		const isTitleCenterAligned =
			isCustomSitesPageTitleFFOn &&
			titleContentProperties?.titleLayoutAlignment == TitleAlignmentType.CENTER;

		const getContributorsData = useMemo(() => {
			const lastModifier: UserShape = version.by;

			const arrangedContributors: UserShape[] = getArrangedContributors({
				owner,
				author,
				lastModifier,
				contributors,
			});

			const userToAvatarGroupDataItem: (user) => userAvatarShape = (user) => ({
				// Props for <ProfileAvatar>
				isAnonymous: user.isAnonymous,
				userId: user.userId,
				src: user.avatarUrl,
				// Extra props for <AkAvatarGroup>
				name: user.fullName,
			});

			return [
				...arrangedContributors
					// Actual data for the users we know about
					.map(userToAvatarGroupDataItem),
				// Skeletons for the ones we don't
				...Array(Math.max(contributorsCount - arrangedContributors.length, 0))
					.fill(null)
					.map(() => ({
						src: png_1x1_n40,
						name: <StyledNameSkeleton />,
					})),
			];
		}, [contributorsCount, version, author, contributors, owner]);

		const allContributorsMayBeNeeded = useCallback(() => {
			if (contributorsCount === contributors.length) return;
			if (!fetchAllContributors) return;

			// Analytics event is for measuring how many prefetches may be wasteful.
			// Only fire 'dataPrefetched' if we actually start a prefetch
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'dataPrefetched',
					actionSubject: 'moreButton',
					source: 'bylineContributors',
				},
			}).fire();

			fetchAllContributors();
		}, [createAnalyticsEvent, fetchAllContributors, contributors, contributorsCount]);

		const allContributorsAreNeeded = useCallback(() => {
			// Analytics event is for measuring how many prefetches may be wasteful.
			// Always fire 'dataNeeded' when a click/tap on the +x button happened,
			// regardless of whether we still need to fetch more data.
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'dataNeeded',
					actionSubject: 'moreButton',
					source: 'bylineContributors',
				},
			}).fire();

			if (contributorsCount === contributors.length) return;
			if (!fetchAllContributors) return;

			fetchAllContributors();
		}, [createAnalyticsEvent, fetchAllContributors, contributors, contributorsCount]);

		const showMoreButtonProps = useMemo(
			() => ({
				onMouseEnter: allContributorsMayBeNeeded,
				onClick: allContributorsAreNeeded,
			}),
			[allContributorsAreNeeded, allContributorsMayBeNeeded],
		);

		const isBlogPost = templateId === BLOG_POST_SOURCE_TEMPLATE_ENTITY_ID;

		const showByLinePoweredByTemplates = Boolean(
			!isExternalShareRequest && isFabricPage && !isBlogPost && templateId,
		);
		const showByLineContributors = hasByLineContributors ?? true;
		const showByLineExtensions = hasByLineExtensions ?? true;

		const CreatorOrOwnerByline = useMemo(() => {
			if (owner !== null) {
				return (
					<PageOwnershipByline
						owner={owner}
						creator={author}
						createdDate={createdDate}
						templateId={showByLinePoweredByTemplates ? templateId : null}
						contentId={contentId}
						lastOwner={lastOwner}
					/>
				);
			} else {
				return (
					<FormattedMessage
						id="content-header.by-line.created.by.expanded"
						defaultMessage="{showByLinePoweredByTemplates, select, true {Created by {author}, {withTemplate}} false {Created by {author}} other {Created by {author}}}"
						description="Text to show author details as well as if a page was created with a template"
						values={{
							author: (
								<ProfileLink
									userId={author.userId}
									fullName={author.fullName}
									isAnonymous={author.isAnonymous}
									referralSource="profilecard"
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
									style={{ textDecorationColor: token('color.link', N200) }}
								/>
							),
							withTemplate:
								showByLinePoweredByTemplates && !!templateId ? (
									<PoweredByTemplates templateId={templateId} />
								) : (
									''
								),
							showByLinePoweredByTemplates,
						}}
					/>
				);
			}
		}, [
			author,
			owner,
			createdDate,
			templateId,
			showByLinePoweredByTemplates,
			contentId,
			lastOwner,
		]);

		const renderAvatarGroup = useMemo(() => {
			return (
				<StyledAvatars isTitleCenterAligned={isTitleCenterAligned}>
					<AkAvatarGroup
						appearance="stack"
						// @ts-ignore
						data={getContributorsData}
						avatar={ProfileAvatar}
						maxCount={NO_OF_AVATARS}
						size={isTitleCenterAligned ? 'small' : 'medium'}
						showMoreButtonProps={showMoreButtonProps}
					/>
				</StyledAvatars>
			);
		}, [getContributorsData, isTitleCenterAligned, showMoreButtonProps]);

		const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
			e.preventDefault();
			const commentElem = document.querySelector(
				'[data-testid="comments-count"]',
			) as HTMLElement | null;

			if (commentElem) {
				// Used temporarily for the experiment cc-page-comments-quick-navigation
				scrollPageCommentIntoView(commentElem, false, false);
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'clicked',
						actionSubject: 'bylinePageCommentCTA',
						source: 'ByLineWithContributorsComponent',
					},
				}).fire();
			}
		};

		return (
			<StyledMainDiv
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="page-metadata-modification-info"
				data-testid="page-main-div"
				isTitleCenterAligned={isTitleCenterAligned}
			>
				{showByLineContributors && !isTitleCenterAligned && renderAvatarGroup}
				<StyledTextDiv>
					{showByLineContributors && (
						<StyledAuthorDiv isTitleCenterAligned={isTitleCenterAligned}>
							{showByLineContributors && isTitleCenterAligned && renderAvatarGroup}
							{CreatorOrOwnerByline}
						</StyledAuthorDiv>
					)}
					<div>
						{showByLineContributors && (
							<ByLineLastModified
								version={version}
								author={author}
								contentId={contentId}
								versionComment={versionComment}
							/>
						)}
						{isPageCommentNavigationCTAEnabled && commentCount > 0 ? (
							<CommentCountContainer onClick={handleClick}>
								<FormattedMessage
									{...i18n.commentCount}
									values={{
										count: commentCount,
									}}
								/>
							</CommentCountContainer>
						) : null}
						{showByLineExtensions && (
							<Fragment>
								<ReadTime contentId={contentId} />
								<AnalyticsByLine contentId={contentId} />
								{!isExternalShareRequest && (
									<ErrorBoundary attribution={Attribution.ECOSYSTEM}>
										<ByLineApps contentId={contentId} />
									</ErrorBoundary>
								)}
								{!isExternalShareRequest && <AutoConversionByLine />}
							</Fragment>
						)}
					</div>
				</StyledTextDiv>
				<ExperienceSuccess name={VIEW_PAGE_BYLINE_EXPERIENCE} />
			</StyledMainDiv>
		);
	},
);
